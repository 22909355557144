.search-result {
  padding: 2.5rem 0 4.5rem;
  border-top: 1px palette(grey) solid;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  max-width: 100%;

  @media only screen and (min-width: $screen-large) {
    justify-content: space-between;
    flex-direction: row;
  }

  &.view--grid {
    flex-direction: column;
    justify-content: space-between;
  }

  &__links {
    display: flex;
    align-items: stretch;

    @media only screen and (min-width: $screen-large) {
      flex-direction: column;
    }

    > a {
      white-space: nowrap;
      display: inline-block;
      margin: 0 1rem 0 0;
      @media only screen and (min-width: $screen-large) {
        margin: 0;
      }
    }

    > button {
      white-space: nowrap;
      margin: 0 1rem 0 0;

      @media only screen and (min-width: $screen-large) {
        margin: 1rem 0 0 0;
      }
    }
  }
}
