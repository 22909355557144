.header {
  margin: 2rem 0;

  .main-grid {
    // we need to be able to see the list dropdown
    overflow: visible;
  }

  &__wrap {
    min-height: 4.7rem;
    @media only screen and (min-width: $screen-medium) {
      min-height: 13.5rem;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: 4.9rem;
  }

  &__ham-icon {
    width: 2.2rem;
    height: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    &--line {
      width: 100%;
      height: 0.2rem;
      background: palette(black);
    }

    @media only screen and (min-width: $screen-large) {
      display: none;
    }
  }

  &__mobile-icon {
    width: 3.6rem;
    height: 3.6rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media only screen and (min-width: $screen-large) {
      display: none;
    }
  }

  &__logo {
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: 10.2rem;
      object-fit: contain;
      object-position: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $screen-large) {
      flex-grow: 1;
      margin-left: 5rem;
    }

    .info__top {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 2rem;
      border-bottom: 1px solid palette(grey, light);
      margin-bottom: 3.5rem;

      .text-sizes {
        display: flex;
      }

      .user {
        margin-right: 4rem;
        font-size: 1.8rem;

        a {
          color: palette(base);

          &:hover {
            color: palette(red);
          }
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
  }

  &__lists {
    display: flex;
    align-items: center;

    .menu-item {
      background-color: transparent;
      padding: 0;
      font-weight: $weight-bold;
      border: none;
    }
  }

  &__search {
    display: flex;
    justify-content: flex-end;

    @media only screen and (min-width: $screen-large) {
      width: 60%;
    }

    &--mobile {
      &.search--open {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    .search {
      &__wrap {
        display: flex;
        align-items: center;
        width: 100%;

        @media only screen and (min-width: $screen-large) {
          display: block;
        }
      }

      &__form {
        position: relative;
        margin: 0;

        input {
          margin: 0;
        }

        label {
          pointer-events: none;
        }
      }

      &__field {
        border: 1px solid rgba(30, 30, 30, 0.3);

        &:focus,
        &:active,
        &:valid {
          + .search__label {
            top: -1rem;
            transition: all 0.1s ease-in-out;
          }
        }
      }

      &__label {
        position: absolute;
        top: 50%;
        left: 1.6rem;
        transform: translateY(-50%);
        transition: all 0.1s ease-in-out;
      }

      &__submit {
        position: absolute;
        top: 50%;
        right: 1.6rem;
        transform: translateY(-50%);
        border: none;
        height: 1.6rem;
        width: 1.6rem;
        background-color: transparent;
        padding: 0;
        background-image: url("../../../public/images/svg/search.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }

      &__open {
        border: none;
        height: 1.6rem;
        width: 1.6rem;
        background-color: transparent;
        padding: 0;
        margin: 0;
        background-image: url("../../../public/images/svg/search.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }

      &__mobile {
        display: flex;
        align-items: center;
        width: 100%;

        .search {
          &__form {
            width: 100%;
          }
        }
      }
    }
  }

  .mobile-menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: palette(white);
    z-index: 99999999;
    overflow: hidden;

    &__header {
      padding: 1.8rem 0;
      border-bottom: 1px solid palette(grey, light);

      .close-menu {
        position: relative;
        height: 2.2rem;
        width: 2.2rem;

        &--line {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 2px;
          background-color: palette(black);

          &:nth-child(1) {
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &:nth-child(2) {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }

    &__content {
      height: calc(100vh - 5.8rem);
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          padding: 1.9rem 0;
          border-bottom: 1px solid palette(grey, light);
          margin: 0;

          a {
            text-transform: uppercase;
            font-weight: bold;
          }
        }
      }

      &__text-buttons {
        margin-top: 3rem;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      margin-bottom: 2.4rem;
      gap: 1rem;
      position: fixed;
      bottom: 0;
      width: calc(100% - 48px);

      .user {
        display: none;
      }

      .menu-item {
        flex: 1;
        margin: 0;
        border-radius: $base-border-radius;
        border: 1px solid palette(red);
        color: palette(white) !important;
        display: inline-block;
        font-weight: $weight-bold;
        text-decoration: none;
        text-align: center;
        font-family: $base-font-family;
        font-size: 1.6rem;
        line-height: 2.4rem;
        padding: 1.3rem 2.5rem 0.9rem;
        transition: all $transition-passive;
        cursor: pointer;
        background-color: palette(red);
        text-transform: uppercase;

        &:hover,
        &:focus {
          transition: all $transition-active;
          background-color: palette(red, dark);
          color: palette(white);
        }

        &:active {
          background-color: palette(red, dark);
        }
      }
    }
  }
}

.text-size {
  height: 3.2rem;
  width: 3.8rem;
  padding: 0;
  margin: 0;
  border: 1px solid palette(grey, light);
  background-color: palette(white);
  color: palette(base);
  line-height: 2.4rem;
  font-weight: $weight-regular;

  &:hover,
  &:focus {
    background-color: palette(grey, light);
  }

  &--small {
    font-size: 1.4rem;
    border-radius: 3px 0 0 3px;
  }

  &--large {
    font-size: 2rem;
    border-radius: 0 3px 3px 0;
    border-left: none;
  }
}
