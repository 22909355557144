.loading__container {
  width: 8rem;
  height: 8rem;
}

.loading__spinner {
  stroke-dasharray: 125;
  stroke-dashoffset: 0;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
