.login {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;

  &__overlay {
    background-color: rgba(palette(base), 0.5);
    height: 100%;
    position: relative;
    cursor: pointer;
  }

  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: palette(white);
    opacity: 1 !important;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    cursor: default;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 200;

    @media only screen and(min-width: $screen-large) {
      max-width: 60rem;
      height: 62rem;
    }
  }
}
