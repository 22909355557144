.card {
  background-color: palette(white);
  position: relative;

  * {
    color: palette(base);
  }

  &__img {
    // this div is acting as an aspect ratio box, getting all its height from padding-top as
    // 123.5% of the width to maintain aspect-ratio of speaker cards
    // more info: https://css-tricks.com/aspect-ratio-boxes/
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(202 / 312 * 100%);
    overflow: hidden;
    @media only screen and (min-width: $screen-large) {
      padding-top: calc(172 / 265 * 100%);
    }

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $card-img-transition;
      transform: scale(1);
    }
  }

  &__content {
    h3 {
      padding-top: 1.6rem;
      transition: $card-h3-transition;
    }

    &__desc {
      padding-top: 0.8rem;
    }

    &__cta-wrap {
      padding-top: 2.4rem;
    }
  }

  .cta-link {
    color: palette(red);
    transition: $card-h3-transition;

    &:hover,
    &:focus {
      color: palette(red, dark);
      transition: $card-h3-hover-transition;
    }
  }

  &:hover,
  &:focus {
    img {
      transform: scale(1.03);
      transition: $card-img-hover-transition;
    }

    h3 {
      color: palette(red);
      transition: $card-h3-hover-transition;
    }
  }
}

.card-document {
  background-color: palette(white);
  position: relative;
  padding-top: 2.4rem;
  border-top: 1px solid palette(grey, light);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 6rem;

  &:last-child {
    border-bottom: 1px solid palette(grey, light);
  }

  @media only screen and (min-width: $screen-medium) {
    padding-bottom: 0;

    &:last-child {
      border-bottom: none;
    }
  }

  h3,
  p {
    color: palette(base);
  }

  h3 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  p {
    padding-top: 0.4rem;
    line-height: 2.4rem;
  }

  .button-wrap {
    padding-top: 0.4rem;
    display: flex;
    align-items: center;

    a {
      margin-right: 1.3rem;
      margin-top: 1rem;
    }

    button {
      margin-top: 1rem;
    }

    .remove {
      margin-right: 0;
      margin-left: auto;
    }
  }
}

.card-list {
  padding: 2rem 0;

  > a {
    position: relative;
    display: block;

    &::after {
      content: "";
      background: url("../../images/icons/arrow-next.svg") no-repeat;
      width: 13px;
      height: 10px;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  p {
    margin-top: 1rem;
  }

  border-top: 1px solid palette(grey, medium);

  &:last-child {
    border-bottom: 1px solid palette(grey, medium);
  }
}
