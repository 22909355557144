.modal {
    a,
    .link {
        text-decoration: underline;
    }

    &__wrap {
        position: relative;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;

        @media only screen and (min-width: $screen-large) {
            padding: 8rem;
        }
    }

    &__close {
        position: absolute;
        top: 3rem;
        right: 3rem;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        z-index: 999;

        &--line {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 2px;
            background-color: palette(base);

            &:first-child {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:last-child {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    &__text {
        
        p {
            margin-top: 0.5rem;
        }

        .link {
            margin-left: 0.5rem;
        }
        &--center {
            p {
              text-align: center;
              margin-bottom: 1rem;
            }
          }
    }

    &__loading {
        display: flex;
        justify-content: center;
        margin: 5rem 0 0;
    }

    &__error {
        display: flex;
        justify-content: center;
        margin-bottom: 2.4rem;

        p {
            font-weight: bold;
            color: palette(red, lldrd);
        }
    }

    &__form {
        &--input {
            position: relative;
            margin: 2.4rem 0;

            > input {
                margin: 0;

                &:focus,
                &:active,
                &:valid {
                    + label {
                        top: -1rem;
                        transition: all 0.1s ease-in-out;
                    }
                }
            }

            > label {
                position: absolute;
                top: 50%;
                left: 1.4rem;
                transform: translateY(-50%);
                transition: all 0.1s ease-in-out;
                pointer-events: none;
            }
        }

        &--submit {
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
                width: 100%;
                margin-bottom: 3rem;
            }
        }
    }
}
