// container-body grid layout
.main-grid {
  display: grid;
  grid-template-columns: minmax(24px, auto) minmax(auto, $grid-max-width) minmax(24px, auto);
  overflow: visible;
  overflow-x: hidden;
  min-height: 0;

  @media only screen and (min-width: $screen-medium) {
    grid-template-columns: minmax(40px, auto) minmax(auto, $grid-max-width) minmax(40px, auto);
  }

  @media only screen and (min-width: $screen-large) {
    grid-template-columns: minmax(80px, auto) minmax(auto, $grid-max-width) minmax(80px, auto);
  }
}

/// the regular content body for the_content. Should be nested inside .container-body
.content-body {
  margin: 0 auto;
  width: 100%;
  @media only screen and (min-width: $screen-small) {
    max-width: $max-content-width-tablet;
  }
  @media only screen and (min-width: $screen-large) {
    max-width: $max-content-width;
  }
}

/// Cards grid

.cards-grid {
  display: grid;
  grid-template-columns: minmax(0, 45rem);
  gap: 6rem;
  justify-content: center;
  @media only screen and (min-width: $screen-medium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: unset;
  }
  @media only screen and (min-width: $screen-large) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 6rem 4rem;
    grid-auto-rows: 1fr;

    .card {
      align-self: start;
      height: 100%;
    }
  }

  &--documents {
    @extend .cards-grid;

    gap: 0;
    grid-template-columns: 1fr;
    justify-content: left;
    @media only screen and (min-width: $screen-medium) {
      gap: 6rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and (min-width: $screen-large) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 4.8rem 6rem;
      grid-auto-rows: 1fr;
    }
    @media only screen and (min-width: $screen-xlarge) {
      gap: 4.8rem 8rem;
    }
  }

  &--lists {
    @extend .cards-grid;

    gap: 0;
    grid-template-columns: minmax(0, auto);
    @media only screen and (min-width: $screen-large) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 2rem;
      grid-auto-rows: 1fr;
    }
  }
}
