.footer {
  background-color: palette(grey);

  &__wrap {
    padding: 7rem 0 4rem;
    display: flex;
    flex-direction: column;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5rem;

    @media only screen and (min-width: $screen-large) {
      padding-bottom: 9rem;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      width: 100%;
      height: 13rem;
      object-fit: contain;
      object-position: center;
    }

    @media only screen and (min-width: $screen-large) {
      flex-basis: 25%;
      justify-content: flex-start;

      img {
        width: 100%;
        height: 10.2rem;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &__text {
    flex-basis: 50%;
    display: none;
    margin: 0 3rem;

    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    @media only screen and (min-width: $screen-large) {
      display: flex;
      margin: 0;
    }
  }

  &__mcgill {
    display: none;

    @media only screen and (min-width: $screen-large) {
      flex-basis: 25%;
      justify-content: flex-end;
      align-items: center;
      display: flex;
    }
  }

  &__bottom {
    display: flex;
    padding-top: 4rem;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: $screen-large) {
      justify-content: space-between;
      flex-direction: row;
      border-top: 1px solid rgba(palette(base), 0.1);
    }

    .mcgill--mobile {
      padding: 3.6rem 0 3rem;
      width: 100%;
      border-top: 1px solid palette(grey, light);
      display: flex;
      justify-content: center;
      margin-top: 5rem;

      @media only screen and (min-width: $screen-large) {
        display: none;
      }
    }
  }

  &__menu {
    ul {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      flex-direction: column;
      align-items: center;

      li {
        margin: 1rem 0;

        a {
          margin: 0;
        }
      }

      @media only screen and (min-width: $screen-large) {
        flex-direction: row;

        li {
          margin: 0;

          a {
            margin-right: 4rem;
          }
        }
      }
    }
  }

  &__copyright span {
    font-size: 1.4rem;
    opacity: 0.5;
  }
}
