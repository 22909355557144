/// styles for the hero module

.hero {
  position: relative;

  &__img {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(288 / 360 * 100%);
    overflow: hidden;
    @media only screen and (min-width: $screen-small) {
      padding-top: calc(510 / 1440 * 100%);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.75;
    }
  }

  &__title {
    color: palette(white);
    text-shadow: $text-shadow;
    text-align: center;
    max-width: calc(100% - 48px);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: $screen-large) {
      max-width: $max-content-width;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__description {
    width: 100%;
    background-color: rgba(palette(black), 0.7);
    margin-top: auto;
    margin-bottom: 0;

    p {
      max-width: $max-content-width;
      color: palette(white);
      margin: 0 auto;
      padding: 3.6rem 0;
      @media only screen and (min-width: $screen-large) {
        padding: 4rem 0;
      }
    }
  }

  &.logged-in {
    .hero__description {
      background-color: palette(base, off-white);

      p {
        color: palette(base);
      }
    }
  }
}
