.user-lists {
  padding: 0 0 11.2rem;

  &__return {
    text-decoration: underline;
    position: relative;
    padding-left: 2rem;

    &::after {
      content: "";
      background: url("../../images/icons/arrow-next.svg") no-repeat;
      width: 13px;
      height: 10px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
    }
    @media only screen and (min-width: $screen-medium) {
      display: none;
    }
  }

  &__title {
    font-size: 2.6rem;
    line-height: 3rem;
    padding: 2.4rem 0 1.2rem;
    margin-top: 1.5rem;
    border-top: 1px solid palette(grey, medium);
    @media only screen and (min-width: $screen-medium) {
      padding: 3.6rem 0 2.4rem;
      border-top: none;
      margin-top: 0;
    }
  }

  &__link-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2.4rem;
  }

  .delete-list {
    display: block;
    margin: 3.6rem auto 0;
  }
}
