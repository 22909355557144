.profile {
  margin: 8rem 0 12rem;
  display: flex;
  justify-content: center;

  &__body {
    padding-top: 2.5rem;
    max-width: 77.3rem;
  }

  &__form {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $screen-large) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 4rem;
    }

    &--input {
      position: relative;
      margin: 3.4rem 0 1rem;

      // force empty cell in the grid by placing this element in the next available column
      &:nth-child(8) {
        grid-column: +1;
      }

      > input {
        margin: 0;
        // this ensures that the label moves up when auto-completing the form
        &:focus,
        &:active,
        &:valid,
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          + label {
            top: -1rem;
            transition: all 0.1s ease-in-out;
          }
        }
      }

      > label {
        position: absolute;
        top: 50%;
        left: 1.4rem;
        transform: translateY(-50%);
        transition: all 0.1s ease-in-out;
        pointer-events: none;
      }
    }

    &--select {
      margin: 3.4rem 0 1rem;
      position: relative;

      > select {
        width: 100%;
      }

      > label {
        position: absolute;
        top: 50%;
        right: 1.6rem;
        transform: translateY(-50%);
        transition: all 0.1s ease-in-out;
        pointer-events: none;
        width: 1.4rem;
        height: 0.8rem;
        display: block;
        background-image: url("../../images/icons/dropdown.svg");
        background-size: contain;
      }
    }

    &--checkbox {
      margin-top: 5rem;
      grid-column: span 2;
      display: flex;

      > input {
        margin-right: 1rem;
        flex-shrink: 0;
      }
    }

    &--button {
      grid-column: span 2;
      margin-top: 5rem;
      display: flex;
      justify-content: center;

      .button {
        width: auto;
      }
    }

    .success {
      grid-column: span 2;
      display: flex;
      justify-content: center;
    }

    .loading {
      grid-column: span 2;
      display: flex;
      justify-content: center;
    }
  }
}
