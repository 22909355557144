.collections {
  padding: 8rem 0 6rem;

  &__wrap {
    min-height: 60vh;
    position: relative;
  }

  &__curtain {
    position: absolute;
    background-color: rgba(palette(black), 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .message {
      background-color: palette(white);
      font-size: 1.8rem;
      padding: 6rem;
      display: none;
      margin-top: 6rem;
    }

    &:hover,
    &:focus {
      .message {
        display: flex;
      }
    }
  }

  .cards-grid {
    padding-top: 4rem;
  }
}
