.documents {
  padding: 8rem 0 11.2rem;

  &__wrap {
    min-height: 90vh;

    &__button-wrap {
      padding-bottom: 4rem;
      @media only screen and (min-width: $screen-large) {
        padding-bottom: 12rem;
      }
    }
  }

  .cards-grid--documents {
    padding-top: 3.6rem;
  }

  .related {
    padding-top: 0;
  }
}

.document-view {
  padding: 6rem 0;
  @media only screen and (min-width: $screen-large) {
    padding: 8rem 0;
  }

  .button-wrap {
    padding-bottom: 4rem;
    @media only screen and (min-width: $screen-large) {
      padding-bottom: 5.7rem;
    }

    .button--download--large {
      margin-top: 3rem;
      width: 100%;

      @media only screen and (min-width: $screen-medium) {
        margin-right: 2.4rem;
        margin-top: 0;
        width: unset;
      }
    }

    .button--list--large {
      margin-top: 2rem;
      width: 100%;
      @media only screen and (min-width: $screen-medium) {
        margin-top: 0;
        width: unset;
      }
    }
  }

  &__details {
    border-top: 1px solid palette(grey, light);
    padding: 2.4rem 0 1.2rem;

    h4 {
      margin-bottom: 0.5rem;
    }

    > div {
      margin-bottom: 2.4rem;
    }
  }

  &__error {
    text-align: center;
    font-size: 2rem;
    padding-top: 6rem;
  }
}
