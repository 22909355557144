.search-results {
  .search-terms {
    padding: 4.8rem 0;
    background-color: palette(grey);
    margin-bottom: 4.8rem;

    .filters {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .filter {
        display: flex;
        align-items: center;
        margin-right: 3rem;

        > p {
          color: palette(red);
        }

        &__remove {
          margin-left: 0.5rem;
          height: 1.5rem;
          width: 1.5rem;
          position: relative;
          flex-shrink: 0;

          > span {
            height: 2px;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: palette(red);

            &:first-child {
              transform: translateX(-50%) rotate(45deg);
            }

            &:last-child {
              transform: translateX(-50%) rotate(-45deg);
            }
          }
        }
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 1220px) {
      gap: clamp(1rem, 5rem, 9rem);
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
    }

    .filters {
      height: 100%;
      top: 0;

      &__wrap {
        display: flex;
        flex-direction: column;
        top: 0;
        position: sticky;
        align-self: start;
      }

      &__title {
        margin-bottom: 2rem;
      }
    }

    // single filter styles
    .filter {
      overflow: hidden;
      border-top: 1px solid palette(grey);
      border-bottom: 1px solid palette(grey);
      margin-top: -1px;

      &__title {
        display: flex;
        justify-content: space-between;
        padding: 1.4rem 1.2rem;
        cursor: pointer;

        > p,
        > span {
          line-height: 2rem;
        }

        > span {
          font-size: 2rem;
        }
      }

      &__list {
        padding: 0 1.2rem;
        height: 0;

        &__item {
          padding: 0.5rem 0;
          font-size: 1.4rem;
          line-height: 1.8rem;
          cursor: pointer;

          &.active {
            color: palette(red);
          }

          &:hover {
            color: palette(red);
          }

          &--date {
            cursor: pointer;
            ::-webkit-datetime-edit-fields-wrapper { background-color: palette(white); }
          }
        }
      }

      &.open {
        .filter__list {
          height: auto;
          padding: 1.4rem 1.2rem;
        }
      }

      &--date {
        input {
          padding: 1.5rem;
          color: palette(black);
        }
      }
    }

    .results {
      &__nav {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;

        .link {
          text-decoration: underline;

          .mobile-filters {
            position: fixed;
            height: 100vh;
            width: 100vw;
            top: 0;
            left: 0;
            z-index: 999;

            &__overlay {
              background-color: rgba(palette(base), 0.5);
              height: 100%;
              position: relative;
              cursor: pointer;
            }

            &__modal {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: palette(white);
              opacity: 1 !important;
              width: calc(100% - 2rem);
              height: calc(100% - 2rem);
              cursor: default;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

              @media only screen and(min-width: 1220px) {
                max-width: 60rem;
                height: 62rem;
              }

              .modal__wrap {
                overflow: scroll;
              }
            }
          }
        }
      }

      &__list {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        min-height: 20rem;

        &.view--grid {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          gap: 3rem 2rem;

          @media only screen and (min-width: $screen-medium) {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
          }

          @media only screen and (min-width: $screen-large) {
            grid-template-columns:
              minmax(0, 1fr) minmax(0, 1fr) minmax(
                0,
                1fr
              );
          }
        }
      }
    }
  }

  .loading__container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
