.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 5rem 0 12rem;
  max-width: 100%;
  overflow-x: scroll;

  &__wrap {
    display: flex;
  }

  &__page {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.current {
      background-color: palette(red);
      color: palette(white);
      border-radius: 5px;
    }
  }

  &__arrow {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.prev {
      transform: rotate(180deg);
    }
  }
}
