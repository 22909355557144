@charset "UTF-8";
/**
 * Colour Variables and Palettes => Sass Map
 *
 */
/* stylelint-disable */
/**
 * Grid, Screen, Typography
 */
button,
.button,
.button--added,
.button--list,
.button--load-more,
.button--list--large,
.button--download,
.button--download--large,
.button--view {
  border-radius: 5px;
  border: 1px solid #9e0918;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  font-family: helvetica, arial, sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.3rem 2.5rem 0.9rem;
  transition: all 500ms;
  cursor: pointer;
  background-color: #9e0918;
  text-transform: uppercase;
}
button:hover, button:focus,
.button:hover,
.button--added:hover,
.button--list:hover,
.button--load-more:hover,
.button--list--large:hover,
.button--download:hover,
.button--download--large:hover,
.button--view:hover,
.button:focus,
.button--added:focus,
.button--list:focus,
.button--load-more:focus,
.button--list--large:focus,
.button--download:focus,
.button--download--large:focus,
.button--view:focus {
  transition: all 250ms;
  background-color: #7d0713;
  color: #fff;
}
button:active,
.button:active,
.button--added:active,
.button--list:active,
.button--load-more:active,
.button--list--large:active,
.button--download:active,
.button--download--large:active,
.button--view:active {
  background-color: #7d0713;
}

a:link.button, a.button--added:link, a.button--list:link, a.button--load-more:link, a.button--list--large:link, a.button--download:link, a.button--download--large:link, a.button--view:link {
  color: #fff;
}

.cta-link {
  color: #9e0918;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: bold;
  transition: all 500ms;
  cursor: pointer;
  text-transform: uppercase;
}
.cta-link::after {
  content: url("../../images/icons/arrow-next.svg");
  margin-left: 1rem;
  transition: all 500ms;
}
.cta-link:hover::after {
  transition: all 250ms;
  margin-left: 1.5rem;
}
.cta-link:active {
  transform: translateX(3px);
}
.cta-link:hover, .cta-link:focus {
  color: #7d0713;
  transition: all 250ms;
}

.menu-item {
  color: #1e1e1e;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 900;
  cursor: pointer;
  position: relative;
  transition: ease 500ms;
}
.menu-item:hover, .menu-item:focus {
  color: #1e1e1e;
}
.menu-item:hover::after, .menu-item:focus::after {
  content: "";
  margin-top: 2px;
  height: 4px;
  width: 100%;
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: -2px;
  transition: ease 250ms;
}

.button--view {
  padding: 0.9rem 1.4rem 0.9rem 3.7rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  position: relative;
  transition: all 500ms;
}
.button--view::before {
  content: "";
  background: url("../../images/icons/view-white.svg") no-repeat;
  transition: all 500ms;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 13px;
}
.button--view:hover, .button--view:focus {
  color: #9e0918 !important;
  background-color: #fff;
  transition: all 250ms;
  border: 1px solid rgba(158, 9, 24, 0.35);
}
.button--view:hover::before, .button--view:focus::before {
  content: "";
  background: url("../../images/icons/view-red.svg") no-repeat;
  transition: all 250ms;
}

.button--download, .button--download--large {
  font-size: 1.4rem;
  line-height: 1.6rem;
  position: relative;
}
.button--download::before, .button--download--large::before {
  content: "";
  background: url("../../images/icons/download.svg") no-repeat;
  width: 18px;
  height: 18px;
  transition: all 500ms;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.button--download--large {
  padding: 1.5rem 6.5rem 1.5rem 7.5rem;
}
.button--download--large::before {
  left: 4.5rem;
}

.button--list, .button--load-more, .button--list--large {
  padding: 0.9rem 1.4rem 0.9rem 3.7rem;
  color: #9e0918;
  background-color: #fff;
  border: 1px solid rgba(158, 9, 24, 0.35);
  font-size: 1.4rem;
  line-height: 1.6rem;
  position: relative;
}
.button--list::before, .button--load-more::before, .button--list--large::before {
  content: "";
  background: url("../../images/icons/list-red.svg") no-repeat;
  transition: all 500ms;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 15px;
}
.button--list:hover, .button--load-more:hover, .button--list--large:hover, .button--list:focus, .button--load-more:focus, .button--list--large:focus {
  color: #fff;
  background-color: #9e0918;
  transition: all 250ms;
}
.button--list:hover::before, .button--load-more:hover::before, .button--list--large:hover::before, .button--list:focus::before, .button--load-more:focus::before, .button--list--large:focus::before {
  background: url("../../images/icons/list-white.svg") no-repeat;
  transition: all 250ms;
}
.button--list--large {
  padding: 1.5rem 6.5rem 1.5rem 7.5rem;
}
.button--list--large::before {
  left: 4.5rem;
}

.button--added {
  position: relative;
  padding-left: 4rem;
}
.button--added::before {
  content: "";
  background: url("../../images/icons/added-check.svg") no-repeat;
  width: 16px;
  height: 13px;
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.button--load-more {
  padding: 1.3rem 1.4rem;
  width: 100%;
  margin-top: 3.9rem;
}
.button--load-more::before {
  display: none;
}
@media only screen and (min-width: 600px) {
  .button--load-more {
    width: unset;
    margin: 3.6rem auto 0;
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .button--load-more {
    margin: 8rem auto 0;
  }
}

.button--underline {
  color: #9e0918;
  background-color: transparent;
  border: none;
  text-transform: capitalize;
  text-decoration: underline;
  letter-spacing: normal;
  font-weight: 400;
}
.button--underline:hover, .button--underline:focus {
  background-color: transparent;
  color: #7d0713;
}

.main-grid {
  display: grid;
  grid-template-columns: minmax(24px, auto) minmax(auto, 1440px) minmax(24px, auto);
  overflow: visible;
  overflow-x: hidden;
  min-height: 0;
}
@media only screen and (min-width: 768px) {
  .main-grid {
    grid-template-columns: minmax(40px, auto) minmax(auto, 1440px) minmax(40px, auto);
  }
}
@media only screen and (min-width: 1024px) {
  .main-grid {
    grid-template-columns: minmax(80px, auto) minmax(auto, 1440px) minmax(80px, auto);
  }
}

.content-body {
  margin: 0 auto;
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .content-body {
    max-width: 650px;
  }
}
@media only screen and (min-width: 1024px) {
  .content-body {
    max-width: 773px;
  }
}

.cards-grid, .cards-grid--lists, .cards-grid--documents {
  display: grid;
  grid-template-columns: minmax(0, 45rem);
  gap: 6rem;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .cards-grid, .cards-grid--lists, .cards-grid--documents {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: unset;
  }
}
@media only screen and (min-width: 1024px) {
  .cards-grid, .cards-grid--lists, .cards-grid--documents {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 6rem 4rem;
    grid-auto-rows: 1fr;
  }
  .cards-grid .card, .cards-grid--lists .card, .cards-grid--documents .card {
    align-self: start;
    height: 100%;
  }
}
.cards-grid--documents {
  gap: 0;
  grid-template-columns: 1fr;
  justify-content: left;
}
@media only screen and (min-width: 768px) {
  .cards-grid--documents {
    gap: 6rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media only screen and (min-width: 1024px) {
  .cards-grid--documents {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 4.8rem 6rem;
    grid-auto-rows: 1fr;
  }
}
@media only screen and (min-width: 1441px) {
  .cards-grid--documents {
    gap: 4.8rem 8rem;
  }
}
.cards-grid--lists {
  gap: 0;
  grid-template-columns: minmax(0, auto);
}
@media only screen and (min-width: 1024px) {
  .cards-grid--lists {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
    grid-auto-rows: 1fr;
  }
}

.focal-point__center--center img {
  object-position: center center;
}
.focal-point__center--left img {
  object-position: center left;
}
.focal-point__center--right img {
  object-position: center right;
}
.focal-point__top--center img {
  object-position: top center;
}
.focal-point__top--left img {
  object-position: top left;
}
.focal-point__top--right img {
  object-position: top right;
}
.focal-point__bottom--center img {
  object-position: bottom center;
}
.focal-point__bottom--left img {
  object-position: bottom left;
}
.focal-point__bottom--right img {
  object-position: bottom right;
}

input,
select {
  vertical-align: middle;
}

button,
input,
select,
textarea {
  margin: 0;
}

button {
  width: auto;
  overflow: visible;
}

fieldset {
  border: none;
  padding: 0;
}

legend {
  font-weight: 900;
  padding: 0 1em;
  margin-left: -1em;
  display: block;
}

label,
.label {
  display: block;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
  opacity: 0.6;
}

select {
  background-color: transparent;
  border: 1px solid rgba(30, 30, 30, 0.3);
  color: rgba(30, 30, 30, 0.6);
  display: block;
  font-size: 1.6rem;
  transition: all 0.2s linear 0s;
  border-radius: 0;
  width: 100%;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
}
select:not([type=range]):not([type=submit]):focus {
  border-color: #1e1e1e;
}

select[multiple] {
  height: 6.5em;
  padding: 1em;
}

.select-wrapper-arrow {
  position: relative;
}
.select-wrapper-arrow::after {
  content: url("../../images/icons/icon-arrow-down.svg");
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

textarea,
input,
.text-input {
  appearance: none !important;
  padding: 1.5rem 12rem 1.5rem 1.5rem;
  font-size: 1.6rem;
  line-height: 1.7rem;
  width: 100%;
  border: 1px solid rgba(30, 30, 30, 0.3);
  font-family: helvetica, arial, sans-serif;
  -webkit-appearance: none;
  border-radius: 0;
  margin-bottom: 2rem;
}

textarea {
  height: 150px;
  resize: vertical;
  font-family: helvetica, arial, sans-serif;
  -webkit-appearance: none;
}

/* stylelint-disable */
::-webkit-input-placeholder {
  color: #1e1e1e;
  font-size: 1.6rem;
  border-radius: 0;
  font-weight: 900;
}

::-moz-placeholder {
  color: #1e1e1e;
  font-size: 1.6rem;
  border-radius: 0;
  font-weight: 900;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input:-ms-input-placeholder {
  color: #1e1e1e;
  font-size: 1.6rem;
  border-radius: 0;
  font-weight: 900;
}

/* stylelint-enable */
.checkbox,
.radio {
  cursor: pointer;
  display: block;
  padding-left: 2.4em;
  margin-bottom: 1em;
  position: relative;
}
.checkbox.inline,
.radio.inline {
  display: inline-block;
  margin-right: 1em;
}
.checkbox input,
.radio input {
  background-color: #d4d4d4;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

input[type=checkbox],
input[type=radio] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  padding: 0;
  margin: 0;
  position: relative;
}
input[type=checkbox]::before,
input[type=radio]::before {
  content: "";
}

input[type=checkbox] {
  border-radius: 0;
  text-align: center;
}
input[type=checkbox]::before {
  content: "✕";
  display: block;
  font-weight: bold;
  line-height: 1.6em;
  transform: translate(-1000%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
input[type=checkbox]:checked::before {
  transform: translate(-50%, -50%);
}

input[type=radio] {
  border-radius: 100%;
}
input[type=radio]::before {
  border-radius: 100%;
  content: "";
  margin: -0.8em;
  width: 0.8em;
  height: 0.8em;
  position: relative;
  top: 50%;
  left: -100%;
}
input[type=radio]:checked::before {
  display: block;
  left: 50%;
}

input[type=button],
input[type=submit] {
  -webkit-appearance: none;
}

input[type=file] {
  padding: 1em;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
}

.field-xsmall {
  width: 2em;
}

.field-small {
  width: 3em;
}

.field-medium {
  width: 4em;
}

.field-large {
  width: 6em;
}

.field-error,
.field-help {
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-style: italic;
  padding-top: 0.5em;
  font-family: helvetica, arial, sans-serif;
  margin: 0;
  width: 49%;
  text-transform: inherit !important;
  letter-spacing: inherit !important;
}

.field-error {
  color: #cc2841;
  float: right;
  text-align: right;
}

.field-help {
  float: left;
}

.field-success {
  font-size: 2rem;
  line-height: 2.8rem;
  font-style: italic;
  padding-top: 2rem;
  font-family: helvetica, arial, sans-serif;
  margin: 0;
  color: #8aa37b;
}

form ol > li,
form ol li > li {
  list-style: none;
  margin: 0;
  padding: 0;
}
form ol li {
  padding-bottom: 1.8em;
}
form ol li::after {
  content: "";
  display: table;
  clear: both;
}
form ol li input,
form ol li select,
form ol li textarea {
  width: 100%;
}
form ol li li:last-child {
  padding-bottom: 0;
}
form ol li select {
  width: 100%;
}

.desktop-only {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .desktop-only {
    display: block;
  }
}

.menu-item,
.menu-item a {
  color: #1e1e1e !important;
  text-decoration: none;
  line-height: 2.2rem;
  margin-right: 4rem;
  font-weight: 400;
}
.menu-item:hover,
.menu-item a:hover {
  color: #9e0918 !important;
}

.invalid-feedback {
  margin: 0;
  color: #9e0918;
  font-family: helvetica, arial, sans-serif;
  line-height: 2.4rem;
  font-weight: 400;
}
@media only screen and (min-width: 1024px) {
  .invalid-feedback {
    line-height: 3rem;
  }
}

.mobile-only {
  display: block;
}
@media only screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .hide-mobile {
    display: none !important;
  }
}

.hide-desktop {
  display: block;
}
@media only screen and (min-width: 1024px) {
  .hide-desktop {
    display: none;
  }
}

.align--right {
  text-align: right;
}
.align--center {
  text-align: center;
}

.margin__top--xsmall {
  margin-top: 1rem;
}
.margin__top--small {
  margin-top: 2rem;
}
.margin__top--small-med {
  margin-top: 3rem;
}
.margin__top--med {
  margin-top: 3rem;
}
@media only screen and (min-width: 1024px) {
  .margin__top--med {
    margin-top: 5rem;
  }
}
.margin__top--med-large {
  margin-top: 4rem;
}
@media only screen and (min-width: 1024px) {
  .margin__top--med-large {
    margin-top: 8rem;
  }
}
.margin__top--large {
  margin-top: 6rem;
}
@media only screen and (min-width: 1024px) {
  .margin__top--large {
    margin-top: 10rem;
  }
}
.margin__top--xlarge {
  margin-top: 12rem;
}
@media only screen and (min-width: 1024px) {
  .margin__top--xlarge {
    margin-top: 16rem;
  }
}
.margin__bottom--xsmall {
  margin-bottom: 1rem;
}
.margin__bottom--small {
  margin-bottom: 2rem;
}
.margin__bottom--small-med {
  margin-bottom: 3rem;
}
.margin__bottom--med {
  margin-bottom: 3rem;
}
@media only screen and (min-width: 1024px) {
  .margin__bottom--med {
    margin-bottom: 5rem;
  }
}
.margin__bottom--med-large {
  margin-bottom: 4rem;
}
@media only screen and (min-width: 1024px) {
  .margin__bottom--med-large {
    margin-bottom: 8rem;
  }
}
.margin__bottom--large {
  margin-bottom: 6rem;
}
@media only screen and (min-width: 1024px) {
  .margin__bottom--large {
    margin-bottom: 10rem;
  }
}
.margin__bottom--xlarge {
  margin-bottom: 12rem;
}
@media only screen and (min-width: 1024px) {
  .margin__bottom--xlarge {
    margin-bottom: 16rem;
  }
}

.padding__top--xsmall {
  padding-top: 1rem;
}
.padding__top--small {
  padding-top: 2rem;
}
.padding__top--small-med {
  padding-top: 3rem;
}
.padding__top--med {
  padding-top: 3rem;
}
@media only screen and (min-width: 1024px) {
  .padding__top--med {
    padding-top: 5rem;
  }
}
.padding__top--med-large {
  padding-top: 4rem;
}
@media only screen and (min-width: 1024px) {
  .padding__top--med-large {
    padding-top: 8rem;
  }
}
.padding__top--large {
  padding-top: 6rem;
}
@media only screen and (min-width: 1024px) {
  .padding__top--large {
    padding-top: 10rem;
  }
}
.padding__top--xlarge {
  padding-top: 12rem;
}
@media only screen and (min-width: 1024px) {
  .padding__top--xlarge {
    padding-top: 16rem;
  }
}
.padding__bottom--xsmall {
  padding-bottom: 1rem;
}
.padding__bottom--small {
  padding-bottom: 2rem;
}
.padding__bottom--small-med {
  padding-bottom: 3rem;
}
.padding__bottom--med {
  padding-bottom: 3rem;
}
@media only screen and (min-width: 1024px) {
  .padding__bottom--med {
    padding-bottom: 5rem;
  }
}
.padding__bottom--med-large {
  padding-bottom: 4rem;
}
@media only screen and (min-width: 1024px) {
  .padding__bottom--med-large {
    padding-bottom: 8rem;
  }
}
.padding__bottom--large {
  padding-bottom: 6rem;
}
@media only screen and (min-width: 1024px) {
  .padding__bottom--large {
    padding-bottom: 10rem;
  }
}
.padding__bottom--xlarge {
  padding-bottom: 12rem;
}
@media only screen and (min-width: 1024px) {
  .padding__bottom--xlarge {
    padding-bottom: 16rem;
  }
}

.white {
  color: #fff;
}

.subtitle, .blockquote {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 400;
}

.button-wrap--center {
  display: flex;
  justify-content: center;
}

ul {
  list-style: disc outside;
  margin-left: 0;
  margin-bottom: 1.75em;
}
@media only screen and (min-width: 768px) {
  ul {
    margin-left: 1em;
  }
}

ol {
  list-style: none;
  counter-reset: cpa-counter;
}
ol li {
  counter-increment: cpa-counter;
  text-indent: -1em;
}
ol li::before {
  content: counter(cpa-counter) ".";
  font-weight: 900;
  margin-right: 16px;
  display: inline-block;
}

li > ol,
li > ul {
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-top: 1.2rem;
}

li {
  margin-bottom: 0.875em;
}
li:last-child {
  margin-bottom: 0;
}

ul.circle,
ol.circle {
  list-style: circle outside;
}
ul.square,
ol.square {
  list-style: square outside;
}
ul.none,
ol.none {
  list-style: none;
  margin-left: 0;
}
ul.leading-zero,
ol.leading-zero {
  list-style: decimal-leading-zero;
}
ul.lower-roman,
ol.lower-roman {
  list-style: lower-roman;
}
ul.upper-roman,
ol.upper-roman {
  list-style: upper-roman;
}
ul.lower-alpha,
ol.lower-alpha {
  list-style: lower-alpha;
}
ul.upper-alpha,
ol.upper-alpha {
  list-style: upper-alpha;
}

dl {
  margin-bottom: 1.75em;
}

dt {
  font-weight: bold;
  margin-top: 1.75em;
}

dd {
  padding-left: 1.75em;
}

dl dt:first-child {
  margin-top: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  clear: both;
  border-left: 0;
}

caption {
  padding-bottom: 0.5em;
}

thead tr {
  border-bottom: 3px #1e1e1e solid;
}

tbody tr {
  border-bottom: 1px #1e1e1e solid;
}
tbody tr.active {
  background: #f4f4f4;
}
tbody tr:last-child {
  border-bottom: 0;
}
tbody th {
  border-right: 3px #1e1e1e solid;
  text-align: right;
  width: 0.1%;
}

td,
th {
  padding: 0.75em;
  text-align: left;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

.table-condensed td,
.table-condensed th {
  padding: 0.25em 0.75em;
}

.table-hover tr:hover {
  background: #f4f4f4;
  transition: 0.2s all ease-in;
}

html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  color: #1e1e1e;
  font-size: 1.6rem;
  font-family: helvetica, arial, sans-serif;
  line-height: 2.8rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
body.font--large {
  font-size: 2rem;
}
body.modal--open {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

main {
  min-height: 100vh;
}

hr {
  border: 0;
  display: block;
  height: 2px;
  background: #f4f4f4;
  margin: 1.75em 0;
  padding: 0;
}

/* stylelint-disable */
/* stylelint-enable */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: #1e1e1e;
  margin: 0;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
}

h1 {
  font-size: 2.6rem;
  line-height: 3rem;
}
@media only screen and (min-width: 1024px) {
  h1 {
    font-size: 4.8rem;
    line-height: 5.4rem;
  }
}

h2 {
  font-size: 2.6rem;
  line-height: 3rem;
}
@media only screen and (min-width: 1024px) {
  h2 {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
}

h3 {
  font-size: 1.8rem;
  line-height: 2.3rem;
}

h4 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

p {
  margin: 0;
  color: #1e1e1e;
  font-family: helvetica, arial, sans-serif;
  line-height: 2.4rem;
  font-weight: 400;
}
@media only screen and (min-width: 1024px) {
  p {
    line-height: 3rem;
  }
}

small,
caption,
.small-text,
.caption {
  font-size: 1.4rem;
  line-height: 2.4rem;
}
@media only screen and (min-width: 1024px) {
  small,
caption,
.small-text,
.caption {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.subtitle, .blockquote {
  font-size: 2.1rem;
  line-height: 3.1rem;
}
@media only screen and (min-width: 1024px) {
  .subtitle, .blockquote {
    font-size: 2.8rem;
    line-height: 4.2rem;
  }
}

.blockquote {
  /* stylelint-disable */
  /* stylelint-enable */
  font-weight: 500;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: 900;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted #d4d4d4;
  cursor: help;
}

address {
  font-style: normal;
}

.weight-regular {
  font-weight: 400;
}

.weight-medium {
  font-weight: 500;
}

.weight-bold {
  font-weight: bold;
}

.weight-black {
  font-weight: 900;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.titlecase {
  text-transform: capitalize;
}

.date,
.location {
  font-size: 1.4rem;
  line-height: 3.2rem;
}
@media only screen and (min-width: 1024px) {
  .date,
.location {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
}

a,
.link {
  transition: ease 250ms;
  font-weight: inherit;
  color: #9e0918;
  cursor: pointer;
  text-decoration: none;
}
a:hover, a:focus,
.link:hover,
.link:focus {
  color: #7d0713;
}
a:link,
.link:link {
  color: #9e0918;
}
a.link-small,
.link.link-small {
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.content-body h1,
.content-body h2,
.content-body h3,
.content-body h4,
.content-body h5,
.content-body h6,
.content-formatting h1,
.content-formatting h2,
.content-formatting h3,
.content-formatting h4,
.content-formatting h5,
.content-formatting h6 {
  font-family: helvetica, arial, sans-serif;
  margin-bottom: 2rem;
}
.content-body p,
.content-formatting p {
  font-family: helvetica, arial, sans-serif;
  margin-bottom: 2rem;
}

pre,
code,
kbd,
samp {
  background-color: #d4d4d4;
  font-family: monaco, courier, monospace;
}

code {
  font-size: 1.4rem;
  padding: 0 0.25em;
}

pre {
  padding: 2em 2em;
}

q {
  font-style: italic;
}

body.font--large p,
body.font--large .menu-item,
body.font--large label,
body.font--large a,
body.font--large input,
body.font--large select {
  font-size: 1.8rem;
  line-height: 2.6rem;
}
@media only screen and (min-width: 1024px) {
  body.font--large p,
body.font--large .menu-item,
body.font--large label,
body.font--large a,
body.font--large input,
body.font--large select {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
}
body.font--large h1 {
  font-size: 3rem;
  line-height: 3.4rem;
}
@media only screen and (min-width: 1024px) {
  body.font--large h1 {
    font-size: 5.2rem;
    line-height: 5.8rem;
  }
}
body.font--large h2 {
  font-size: 3rem;
  line-height: 3.4rem;
}
@media only screen and (min-width: 1024px) {
  body.font--large h2 {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}
body.font--large h3 {
  font-size: 2.2rem;
  line-height: 2.7rem;
}
body.font--large h4 {
  font-size: 2rem;
  line-height: 2.8rem;
}
body.font--large .cta-link {
  font-size: 1.8rem;
  line-height: 2.2rem;
}
body.font--large button:not(.text-size--small),
body.font--large .button,
body.font--large .button--view,
body.font--large .button--download,
body.font--large .button--download--large,
body.font--large .button--list,
body.font--large .button--list--large,
body.font--large .button--added,
body.font--large .button--load-more {
  font-size: 2rem;
  line-height: 2.8rem;
}
body.font--large .filter__list__item {
  font-size: 1.8rem;
  line-height: 2.4rem;
}
body.font--large .pagination__arrow,
body.font--large .pagination__page {
  width: 5rem;
  height: 5rem;
}
body.font--large .search-results__wrap .filter__title > span {
  font-size: 2.4rem;
  line-height: 2.4rem;
}

.header {
  margin: 2rem 0;
}
.header .main-grid {
  overflow: visible;
}
.header__wrap {
  min-height: 4.7rem;
}
@media only screen and (min-width: 768px) {
  .header__wrap {
    min-height: 13.5rem;
  }
}
.header__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 4.9rem;
}
.header__ham-icon {
  width: 2.2rem;
  height: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.header__ham-icon--line {
  width: 100%;
  height: 0.2rem;
  background: #1e1e1e;
}
@media only screen and (min-width: 1024px) {
  .header__ham-icon {
    display: none;
  }
}
.header__mobile-icon {
  width: 3.6rem;
  height: 3.6rem;
}
.header__mobile-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media only screen and (min-width: 1024px) {
  .header__mobile-icon {
    display: none;
  }
}
.header__logo {
  display: flex;
  align-items: center;
}
.header__logo img {
  width: 100%;
  height: 10.2rem;
  object-fit: contain;
  object-position: center;
}
.header__info {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1024px) {
  .header__info {
    flex-grow: 1;
    margin-left: 5rem;
  }
}
.header__info .info__top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 3.5rem;
}
.header__info .info__top .text-sizes {
  display: flex;
}
.header__info .info__top .user {
  margin-right: 4rem;
  font-size: 1.8rem;
}
.header__info .info__top .user a {
  color: #1e1e1e;
}
.header__info .info__top .user a:hover {
  color: #9e0918;
}
.header__bottom {
  display: flex;
  justify-content: flex-end;
}
.header__lists {
  display: flex;
  align-items: center;
}
.header__lists .menu-item {
  background-color: transparent;
  padding: 0;
  font-weight: bold;
  border: none;
}
.header__search {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (min-width: 1024px) {
  .header__search {
    width: 60%;
  }
}
.header__search--mobile.search--open {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.header__search .search__wrap {
  display: flex;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .header__search .search__wrap {
    display: block;
  }
}
.header__search .search__form {
  position: relative;
  margin: 0;
}
.header__search .search__form input {
  margin: 0;
}
.header__search .search__form label {
  pointer-events: none;
}
.header__search .search__field {
  border: 1px solid rgba(30, 30, 30, 0.3);
}
.header__search .search__field:focus + .search__label, .header__search .search__field:active + .search__label, .header__search .search__field:valid + .search__label {
  top: -1rem;
  transition: all 0.1s ease-in-out;
}
.header__search .search__label {
  position: absolute;
  top: 50%;
  left: 1.6rem;
  transform: translateY(-50%);
  transition: all 0.1s ease-in-out;
}
.header__search .search__submit {
  position: absolute;
  top: 50%;
  right: 1.6rem;
  transform: translateY(-50%);
  border: none;
  height: 1.6rem;
  width: 1.6rem;
  background-color: transparent;
  padding: 0;
  background-image: url("../../../public/images/svg/search.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.header__search .search__open {
  border: none;
  height: 1.6rem;
  width: 1.6rem;
  background-color: transparent;
  padding: 0;
  margin: 0;
  background-image: url("../../../public/images/svg/search.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.header__search .search__mobile {
  display: flex;
  align-items: center;
  width: 100%;
}
.header__search .search__mobile .search__form {
  width: 100%;
}
.header .mobile-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 99999999;
  overflow: hidden;
}
.header .mobile-menu__header {
  padding: 1.8rem 0;
  border-bottom: 1px solid #e3e3e3;
}
.header .mobile-menu__header .close-menu {
  position: relative;
  height: 2.2rem;
  width: 2.2rem;
}
.header .mobile-menu__header .close-menu--line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #1e1e1e;
}
.header .mobile-menu__header .close-menu--line:nth-child(1) {
  transform: translate(-50%, -50%) rotate(45deg);
}
.header .mobile-menu__header .close-menu--line:nth-child(2) {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.header .mobile-menu__content {
  height: calc(100vh - 5.8rem);
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.header .mobile-menu__content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.header .mobile-menu__content ul li {
  padding: 1.9rem 0;
  border-bottom: 1px solid #e3e3e3;
  margin: 0;
}
.header .mobile-menu__content ul li a {
  text-transform: uppercase;
  font-weight: bold;
}
.header .mobile-menu__content__text-buttons {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.header .mobile-menu__button {
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
  gap: 1rem;
  position: fixed;
  bottom: 0;
  width: calc(100% - 48px);
}
.header .mobile-menu__button .user {
  display: none;
}
.header .mobile-menu__button .menu-item {
  flex: 1;
  margin: 0;
  border-radius: 5px;
  border: 1px solid #9e0918;
  color: #fff !important;
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  font-family: helvetica, arial, sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.3rem 2.5rem 0.9rem;
  transition: all 500ms;
  cursor: pointer;
  background-color: #9e0918;
  text-transform: uppercase;
}
.header .mobile-menu__button .menu-item:hover, .header .mobile-menu__button .menu-item:focus {
  transition: all 250ms;
  background-color: #7d0713;
  color: #fff;
}
.header .mobile-menu__button .menu-item:active {
  background-color: #7d0713;
}

.text-size {
  height: 3.2rem;
  width: 3.8rem;
  padding: 0;
  margin: 0;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  color: #1e1e1e;
  line-height: 2.4rem;
  font-weight: 400;
}
.text-size:hover, .text-size:focus {
  background-color: #e3e3e3;
}
.text-size--small {
  font-size: 1.4rem;
  border-radius: 3px 0 0 3px;
}
.text-size--large {
  font-size: 2rem;
  border-radius: 0 3px 3px 0;
  border-left: none;
}

.footer {
  background-color: #f4f4f4;
}
.footer__wrap {
  padding: 7rem 0 4rem;
  display: flex;
  flex-direction: column;
}
.footer__top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5rem;
}
@media only screen and (min-width: 1024px) {
  .footer__top {
    padding-bottom: 9rem;
  }
}
.footer__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.footer__logo img {
  width: 100%;
  height: 13rem;
  object-fit: contain;
  object-position: center;
}
@media only screen and (min-width: 1024px) {
  .footer__logo {
    flex-basis: 25%;
    justify-content: flex-start;
  }
  .footer__logo img {
    width: 100%;
    height: 10.2rem;
    object-fit: contain;
    object-position: center;
  }
}
.footer__text {
  flex-basis: 50%;
  display: none;
  margin: 0 3rem;
}
.footer__text p {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
@media only screen and (min-width: 1024px) {
  .footer__text {
    display: flex;
    margin: 0;
  }
}
.footer__mcgill {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .footer__mcgill {
    flex-basis: 25%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }
}
.footer__bottom {
  display: flex;
  padding-top: 4rem;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  .footer__bottom {
    justify-content: space-between;
    flex-direction: row;
    border-top: 1px solid rgba(30, 30, 30, 0.1);
  }
}
.footer__bottom .mcgill--mobile {
  padding: 3.6rem 0 3rem;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}
@media only screen and (min-width: 1024px) {
  .footer__bottom .mcgill--mobile {
    display: none;
  }
}
.footer__menu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-direction: column;
  align-items: center;
}
.footer__menu ul li {
  margin: 1rem 0;
}
.footer__menu ul li a {
  margin: 0;
}
@media only screen and (min-width: 1024px) {
  .footer__menu ul {
    flex-direction: row;
  }
  .footer__menu ul li {
    margin: 0;
  }
  .footer__menu ul li a {
    margin-right: 4rem;
  }
}
.footer__copyright span {
  font-size: 1.4rem;
  opacity: 0.5;
}

.user-lists {
  padding: 0 0 11.2rem;
}
.user-lists__return {
  text-decoration: underline;
  position: relative;
  padding-left: 2rem;
}
.user-lists__return::after {
  content: "";
  background: url("../../images/icons/arrow-next.svg") no-repeat;
  width: 13px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}
@media only screen and (min-width: 768px) {
  .user-lists__return {
    display: none;
  }
}
.user-lists__title {
  font-size: 2.6rem;
  line-height: 3rem;
  padding: 2.4rem 0 1.2rem;
  margin-top: 1.5rem;
  border-top: 1px solid #d4d4d4;
}
@media only screen and (min-width: 768px) {
  .user-lists__title {
    padding: 3.6rem 0 2.4rem;
    border-top: none;
    margin-top: 0;
  }
}
.user-lists__link-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2.4rem;
}
.user-lists .delete-list {
  display: block;
  margin: 3.6rem auto 0;
}

.hero {
  position: relative;
}
.hero__img {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(288 / 360 * 100%);
  overflow: hidden;
}
@media only screen and (min-width: 600px) {
  .hero__img {
    padding-top: calc(510 / 1440 * 100%);
  }
}
.hero__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.75;
}
.hero__title {
  color: #fff;
  text-shadow: 0 0 14px rgba(0, 0, 0, 0.5);
  text-align: center;
  max-width: calc(100% - 48px);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  .hero__title {
    max-width: 773px;
  }
}
.hero__content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.hero__description {
  width: 100%;
  background-color: rgba(30, 30, 30, 0.7);
  margin-top: auto;
  margin-bottom: 0;
}
.hero__description p {
  max-width: 773px;
  color: #fff;
  margin: 0 auto;
  padding: 3.6rem 0;
}
@media only screen and (min-width: 1024px) {
  .hero__description p {
    padding: 4rem 0;
  }
}
.hero.logged-in .hero__description {
  background-color: #f5f5f5;
}
.hero.logged-in .hero__description p {
  color: #1e1e1e;
}

.card {
  background-color: #fff;
  position: relative;
}
.card * {
  color: #1e1e1e;
}
.card__img {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(202 / 312 * 100%);
  overflow: hidden;
}
@media only screen and (min-width: 1024px) {
  .card__img {
    padding-top: calc(172 / 265 * 100%);
  }
}
.card__img > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(1);
}
.card__content h3 {
  padding-top: 1.6rem;
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.card__content__desc {
  padding-top: 0.8rem;
}
.card__content__cta-wrap {
  padding-top: 2.4rem;
}
.card .cta-link {
  color: #9e0918;
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.card .cta-link:hover, .card .cta-link:focus {
  color: #7d0713;
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.card:hover img, .card:focus img {
  transform: scale(1.03);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.card:hover h3, .card:focus h3 {
  color: #9e0918;
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.card-document {
  background-color: #fff;
  position: relative;
  padding-top: 2.4rem;
  border-top: 1px solid #e3e3e3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 6rem;
}
.card-document:last-child {
  border-bottom: 1px solid #e3e3e3;
}
@media only screen and (min-width: 768px) {
  .card-document {
    padding-bottom: 0;
  }
  .card-document:last-child {
    border-bottom: none;
  }
}
.card-document h3,
.card-document p {
  color: #1e1e1e;
}
.card-document h3 {
  font-size: 2rem;
  line-height: 2.4rem;
}
.card-document p {
  padding-top: 0.4rem;
  line-height: 2.4rem;
}
.card-document .button-wrap {
  padding-top: 0.4rem;
  display: flex;
  align-items: center;
}
.card-document .button-wrap a {
  margin-right: 1.3rem;
  margin-top: 1rem;
}
.card-document .button-wrap button {
  margin-top: 1rem;
}
.card-document .button-wrap .remove {
  margin-right: 0;
  margin-left: auto;
}

.card-list {
  padding: 2rem 0;
  border-top: 1px solid #d4d4d4;
}
.card-list > a {
  position: relative;
  display: block;
}
.card-list > a::after {
  content: "";
  background: url("../../images/icons/arrow-next.svg") no-repeat;
  width: 13px;
  height: 10px;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.card-list p {
  margin-top: 1rem;
}
.card-list:last-child {
  border-bottom: 1px solid #d4d4d4;
}

.collections {
  padding: 8rem 0 6rem;
}
.collections__wrap {
  min-height: 60vh;
  position: relative;
}
.collections__curtain {
  position: absolute;
  background-color: rgba(30, 30, 30, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.collections__curtain .message {
  background-color: #fff;
  font-size: 1.8rem;
  padding: 6rem;
  display: none;
  margin-top: 6rem;
}
.collections__curtain:hover .message, .collections__curtain:focus .message {
  display: flex;
}
.collections .cards-grid, .collections .cards-grid--documents, .collections .cards-grid--lists {
  padding-top: 4rem;
}

.login, .list-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}
.login__overlay, .list-modal__overlay {
  background-color: rgba(30, 30, 30, 0.5);
  height: 100%;
  position: relative;
  cursor: pointer;
}
.login__modal, .list-modal__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  opacity: 1 !important;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  cursor: default;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 200;
}
@media only screen and (min-width: 1024px) {
  .login__modal, .list-modal__modal {
    max-width: 60rem;
    height: 62rem;
  }
}

.loading__container {
  width: 8rem;
  height: 8rem;
}

.loading__spinner {
  stroke-dasharray: 125;
  stroke-dashoffset: 0;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.documents {
  padding: 8rem 0 11.2rem;
}
.documents__wrap {
  min-height: 90vh;
}
.documents__wrap__button-wrap {
  padding-bottom: 4rem;
}
@media only screen and (min-width: 1024px) {
  .documents__wrap__button-wrap {
    padding-bottom: 12rem;
  }
}
.documents .cards-grid--documents {
  padding-top: 3.6rem;
}
.documents .related {
  padding-top: 0;
}

.document-view {
  padding: 6rem 0;
}
@media only screen and (min-width: 1024px) {
  .document-view {
    padding: 8rem 0;
  }
}
.document-view .button-wrap {
  padding-bottom: 4rem;
}
@media only screen and (min-width: 1024px) {
  .document-view .button-wrap {
    padding-bottom: 5.7rem;
  }
}
.document-view .button-wrap .button--download--large {
  margin-top: 3rem;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .document-view .button-wrap .button--download--large {
    margin-right: 2.4rem;
    margin-top: 0;
    width: unset;
  }
}
.document-view .button-wrap .button--list--large {
  margin-top: 2rem;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .document-view .button-wrap .button--list--large {
    margin-top: 0;
    width: unset;
  }
}
.document-view__details {
  border-top: 1px solid #e3e3e3;
  padding: 2.4rem 0 1.2rem;
}
.document-view__details h4 {
  margin-bottom: 0.5rem;
}
.document-view__details > div {
  margin-bottom: 2.4rem;
}
.document-view__error {
  text-align: center;
  font-size: 2rem;
  padding-top: 6rem;
}

.list-modal__modal {
  height: 41rem;
}
@media only screen and (min-width: 1024px) {
  .list-modal__modal {
    height: 53rem;
  }
}
.list-modal .modal__wrap {
  justify-content: flex-start;
}
.list-modal .modal h2 {
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: bold;
}
.list-modal .modal__text {
  padding: 0.6rem 0 2.4rem;
}
.list-modal .modal__message {
  margin-top: 2.4rem;
}
.list-modal .modal__message p {
  font-weight: bold;
  font-size: 1.8rem;
}
.list-modal .modal__form--input {
  margin: 1.2rem 0;
}
.list-modal .modal__form--input > input + label {
  font-size: 1.6rem;
  top: 50%;
}
.list-modal .modal__form--input > input + label.input-has-value {
  top: -1rem !important;
  transition: all 0.1s ease-in-out;
}
.list-modal .modal__form--input > input:valid + label {
  top: 50%;
}
.list-modal .modal__form--input > input:focus + label, .list-modal .modal__form--input > input:active + label {
  top: -1rem;
  transition: all 0.1s ease-in-out;
}
.list-modal .modal__form--submit {
  padding-top: 2.7rem;
}
.list-modal .modal__form--submit button {
  width: unset;
}

.my-list__modal {
  max-height: 28.5rem;
  overflow: scroll;
  width: 26.5rem;
  position: absolute;
  top: 13.7rem;
  right: 50%;
  transform: translateX(50%);
  background-color: #fff;
  z-index: 10;
  box-shadow: 0 1px 1px 0 rgba(59, 72, 77, 0.08), 0 1px 3px 1px rgba(59, 72, 77, 0.16);
}
.my-list__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.my-list__list li {
  border-bottom: 1px solid #e3e3e3;
  text-indent: 0;
  padding: 1.4rem 1.8rem;
  margin: 0;
}
.my-list__list li a {
  color: #1e1e1e;
  font-size: 1.6rem;
  line-height: 2.4rem;
  white-space: normal;
  display: inline-block;
  width: 100%;
}
.my-list__list li a:hover, .my-list__list li a:focus {
  color: #9e0918;
}
.my-list__new-list-button {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: #9e0918;
  text-transform: none;
  letter-spacing: 0;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 400;
  border: none;
  text-align: left;
  padding: 1.4rem 1.8rem;
  border-top: 1px solid #e3e3e3;
  border-radius: 0;
}
.my-list__new-list-button::before {
  content: "";
  background: url("../../images/icons/expand.svg") no-repeat;
  transition: all 500ms;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 13px;
}
.my-list__new-list-button:hover::before, .my-list__new-list-button:focus::before {
  background: url("../../images/icons/expand-white.svg") no-repeat;
}

.search-result {
  padding: 2.5rem 0 4.5rem;
  border-top: 1px #f4f4f4 solid;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  max-width: 100%;
}
@media only screen and (min-width: 1024px) {
  .search-result {
    justify-content: space-between;
    flex-direction: row;
  }
}
.search-result.view--grid {
  flex-direction: column;
  justify-content: space-between;
}
.search-result__links {
  display: flex;
  align-items: stretch;
}
@media only screen and (min-width: 1024px) {
  .search-result__links {
    flex-direction: column;
  }
}
.search-result__links > a {
  white-space: nowrap;
  display: inline-block;
  margin: 0 1rem 0 0;
}
@media only screen and (min-width: 1024px) {
  .search-result__links > a {
    margin: 0;
  }
}
.search-result__links > button {
  white-space: nowrap;
  margin: 0 1rem 0 0;
}
@media only screen and (min-width: 1024px) {
  .search-result__links > button {
    margin: 1rem 0 0 0;
  }
}

.modal a,
.modal .link {
  text-decoration: underline;
}
.modal__wrap {
  position: relative;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
@media only screen and (min-width: 1024px) {
  .modal__wrap {
    padding: 8rem;
  }
}
.modal__close {
  position: absolute;
  top: 3rem;
  right: 3rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  z-index: 999;
}
.modal__close--line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #1e1e1e;
}
.modal__close--line:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal__close--line:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.modal__text p {
  margin-top: 0.5rem;
}
.modal__text .link {
  margin-left: 0.5rem;
}
.modal__text--center p {
  text-align: center;
  margin-bottom: 1rem;
}
.modal__loading {
  display: flex;
  justify-content: center;
  margin: 5rem 0 0;
}
.modal__error {
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
}
.modal__error p {
  font-weight: bold;
  color: #f4363d;
}
.modal__form--input {
  position: relative;
  margin: 2.4rem 0;
}
.modal__form--input > input {
  margin: 0;
}
.modal__form--input > input:focus + label, .modal__form--input > input:active + label, .modal__form--input > input:valid + label {
  top: -1rem;
  transition: all 0.1s ease-in-out;
}
.modal__form--input > label {
  position: absolute;
  top: 50%;
  left: 1.4rem;
  transform: translateY(-50%);
  transition: all 0.1s ease-in-out;
  pointer-events: none;
}
.modal__form--submit {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal__form--submit button {
  width: 100%;
  margin-bottom: 3rem;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 5rem 0 12rem;
  max-width: 100%;
  overflow-x: scroll;
}
.pagination__wrap {
  display: flex;
}
.pagination__page {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.pagination__page.current {
  background-color: #9e0918;
  color: #fff;
  border-radius: 5px;
}
.pagination__arrow {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.pagination__arrow.prev {
  transform: rotate(180deg);
}

.search-results .search-terms {
  padding: 4.8rem 0;
  background-color: #f4f4f4;
  margin-bottom: 4.8rem;
}
.search-results .search-terms .filters {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.search-results .search-terms .filters .filter {
  display: flex;
  align-items: center;
  margin-right: 3rem;
}
.search-results .search-terms .filters .filter > p {
  color: #9e0918;
}
.search-results .search-terms .filters .filter__remove {
  margin-left: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  flex-shrink: 0;
}
.search-results .search-terms .filters .filter__remove > span {
  height: 2px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #9e0918;
}
.search-results .search-terms .filters .filter__remove > span:first-child {
  transform: translateX(-50%) rotate(45deg);
}
.search-results .search-terms .filters .filter__remove > span:last-child {
  transform: translateX(-50%) rotate(-45deg);
}
.search-results__wrap {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1220px) {
  .search-results__wrap {
    gap: clamp(1rem, 5rem, 9rem);
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  }
}
.search-results__wrap .filters {
  height: 100%;
  top: 0;
}
.search-results__wrap .filters__wrap {
  display: flex;
  flex-direction: column;
  top: 0;
  position: sticky;
  align-self: start;
}
.search-results__wrap .filters__title {
  margin-bottom: 2rem;
}
.search-results__wrap .filter {
  overflow: hidden;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  margin-top: -1px;
}
.search-results__wrap .filter__title {
  display: flex;
  justify-content: space-between;
  padding: 1.4rem 1.2rem;
  cursor: pointer;
}
.search-results__wrap .filter__title > p,
.search-results__wrap .filter__title > span {
  line-height: 2rem;
}
.search-results__wrap .filter__title > span {
  font-size: 2rem;
}
.search-results__wrap .filter__list {
  padding: 0 1.2rem;
  height: 0;
}
.search-results__wrap .filter__list__item {
  padding: 0.5rem 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
  cursor: pointer;
}
.search-results__wrap .filter__list__item.active {
  color: #9e0918;
}
.search-results__wrap .filter__list__item:hover {
  color: #9e0918;
}
.search-results__wrap .filter__list__item--date {
  cursor: pointer;
}
.search-results__wrap .filter__list__item--date ::-webkit-datetime-edit-fields-wrapper {
  background-color: #fff;
}
.search-results__wrap .filter.open .filter__list {
  height: auto;
  padding: 1.4rem 1.2rem;
}
.search-results__wrap .filter--date input {
  padding: 1.5rem;
  color: #1e1e1e;
}
.search-results__wrap .results__nav {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}
.search-results__wrap .results__nav .link {
  text-decoration: underline;
}
.search-results__wrap .results__nav .link .mobile-filters {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
}
.search-results__wrap .results__nav .link .mobile-filters__overlay {
  background-color: rgba(30, 30, 30, 0.5);
  height: 100%;
  position: relative;
  cursor: pointer;
}
.search-results__wrap .results__nav .link .mobile-filters__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  opacity: 1 !important;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  cursor: default;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
@media only screen and (min-width: 1220px) {
  .search-results__wrap .results__nav .link .mobile-filters__modal {
    max-width: 60rem;
    height: 62rem;
  }
}
.search-results__wrap .results__nav .link .mobile-filters__modal .modal__wrap {
  overflow: scroll;
}
.search-results__wrap .results__list {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 20rem;
}
.search-results__wrap .results__list.view--grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 3rem 2rem;
}
@media only screen and (min-width: 768px) {
  .search-results__wrap .results__list.view--grid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media only screen and (min-width: 1024px) {
  .search-results__wrap .results__list.view--grid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}
.search-results .loading__container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile {
  margin: 8rem 0 12rem;
  display: flex;
  justify-content: center;
}
.profile__body {
  padding-top: 2.5rem;
  max-width: 77.3rem;
}
.profile__form {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1024px) {
  .profile__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
  }
}
.profile__form--input {
  position: relative;
  margin: 3.4rem 0 1rem;
}
.profile__form--input:nth-child(8) {
  grid-column: 1;
}
.profile__form--input > input {
  margin: 0;
}
.profile__form--input > input:focus + label, .profile__form--input > input:active + label, .profile__form--input > input:valid + label, .profile__form--input > input:-webkit-autofill + label, .profile__form--input > input:-webkit-autofill:hover + label, .profile__form--input > input:-webkit-autofill:focus + label {
  top: -1rem;
  transition: all 0.1s ease-in-out;
}
.profile__form--input > label {
  position: absolute;
  top: 50%;
  left: 1.4rem;
  transform: translateY(-50%);
  transition: all 0.1s ease-in-out;
  pointer-events: none;
}
.profile__form--select {
  margin: 3.4rem 0 1rem;
  position: relative;
}
.profile__form--select > select {
  width: 100%;
}
.profile__form--select > label {
  position: absolute;
  top: 50%;
  right: 1.6rem;
  transform: translateY(-50%);
  transition: all 0.1s ease-in-out;
  pointer-events: none;
  width: 1.4rem;
  height: 0.8rem;
  display: block;
  background-image: url("../../images/icons/dropdown.svg");
  background-size: contain;
}
.profile__form--checkbox {
  margin-top: 5rem;
  grid-column: span 2;
  display: flex;
}
.profile__form--checkbox > input {
  margin-right: 1rem;
  flex-shrink: 0;
}
.profile__form--button {
  grid-column: span 2;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
}
.profile__form--button .button, .profile__form--button .button--view, .profile__form--button .button--download, .profile__form--button .button--download--large, .profile__form--button .button--list, .profile__form--button .button--list--large, .profile__form--button .button--added, .profile__form--button .button--load-more {
  width: auto;
}
.profile__form .success {
  grid-column: span 2;
  display: flex;
  justify-content: center;
}
.profile__form .loading {
  grid-column: span 2;
  display: flex;
  justify-content: center;
}