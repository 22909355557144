.my-list {
  &__modal {
    max-height: 28.5rem; // 5 x height .list-item
    overflow: scroll;
    width: 26.5rem;
    position: absolute;
    top: 13.7rem;
    right: 50%;
    transform: translateX(50%);
    background-color: palette(white);
    z-index: $z-index-my-list;
    box-shadow: $box-shadow-default;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid palette(grey, light);
      text-indent: 0;
      padding: 1.4rem 1.8rem;
      margin: 0;

      a {
        color: palette(base);
        font-size: 1.6rem;
        line-height: 2.4rem;
        white-space: normal;
        display: inline-block;
        width: 100%;

        &:hover,
        &:focus {
          color: palette(red);
        }
      }
    }
  }

  &__new-list-button {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: palette(white);
    color: palette(red);
    text-transform: none;
    letter-spacing: 0;
    line-height: 2.4rem;
    font-size: 1.6rem;
    font-weight: $weight-regular;
    border: none;
    text-align: left;
    padding: 1.4rem 1.8rem;
    border-top: 1px solid palette(grey, light);
    border-radius: 0;

    &::before {
      // to do: get icons that are the same size as each other from
      content: "";
      background: url("../../images/icons/expand.svg") no-repeat;
      transition: all $transition-passive;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 13px;
    }

    &:hover,
    &:focus {
      &::before {
        background: url("../../images/icons/expand-white.svg") no-repeat;
      }
    }
  }
}
