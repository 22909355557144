.list-modal {
  @extend .login;

  &__overlay {
    @extend .login__overlay;
  }

  &__modal {
    @extend .login__modal;

    height: 41rem;
    @media only screen and (min-width: $screen-large) {
      height: 53rem;
    }
  }

  .modal {
    &__wrap {
      justify-content: flex-start;
    }

    h2 {
      font-size: 2.8rem;
      line-height: 3.2rem;
      font-weight: $weight-bold;
    }

    &__text {
      padding: 0.6rem 0 2.4rem;
    }

    &__message {
      margin-top: 2.4rem;

      p {
        font-weight: bold;
        font-size: 1.8rem;
      }
    }

    &__form {
      &--input {
        margin: 1.2rem 0;

        > input {
          + label {
            font-size: 1.6rem;
            top: 50%;

            &.input-has-value {
              top: -1rem !important;
              transition: all 0.1s ease-in-out;
            }
          }

          &:valid {
            + label {
              top: 50%;
            }
          }

          &:focus,
          &:active {
            + label {
              top: -1rem;
              transition: all 0.1s ease-in-out;
            }
          }
        }
      }

      &--submit {
        padding-top: 2.7rem;

        button {
          width: unset;
        }
      }
    }
  }
}
