html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  color: palette(base);
  font-size: 1.6rem;
  font-family: $base-font-family;
  line-height: 2.8rem;
  font-weight: $weight-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //   overflow-x: hidden;
  margin: 0;

  &.font--large {
    font-size: 2rem;
  }

  &.modal--open {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

main{
  min-height: 100vh;
}

// ----- other ------

hr {
  border: 0;
  display: block;
  height: 2px;
  background: palette(grey);
  margin: $base-line-height * 1em 0;
  padding: 0;
}
