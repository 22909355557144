/**
 * Grid, Screen, Typography
 */

// Screen Widths
$screen-xxsmall: 320px;
$screen-xsmall: 480px;
$screen-small: 600px;
$screen-medium: 768px; //tablet
$screen-large: 1024px; //desktop
$screen-xlarge: 1441px; // full width

// Grid
$grid-max-width: 1440px;
$grid-large-width: 1440px;
$grid-min-width: 366px;
$gutter-width: 24px;
$gutter-desktop: 24px;
$gutter-tablet: 21px;
$gutter-mobile: 21px;
$gutter-image-grid: 5px;
$max-content-width: 773px;
$max-content-width-tablet: 650px; //* TBD
$max-content-width-mobile: 366px;

// Typography
$base-line-height: 1.75;
$base-font-family: helvetica, arial, sans-serif;

$weight-regular: 400;
$weight-medium: 500;
$weight-bold: bold;
$weight-black: 900;

// Transitions (Defaults if you don't pass parameters to +transition())
$transition-active: 250ms;
$transition-passive: 500ms;
$default-transition-duration: 3s;
$default-transition-function: ease;
$default-transition-delay: 0s;

// Borders
$base-border: 1px palette(base) solid;
$base-border-color: palette(base);
$base-border-radius: 5px;
$box-shadow-default: 0 1px 1px 0 rgba(59, 72, 77, 0.08), 0 1px 3px 1px rgba(59, 72, 77, 0.16);
$box-shadow-filter: 1px 2px 12px 0 rgba(0, 0, 0, 0.25);
$box-shadow-menu: 0 2px 12px 12px rgba(0, 0, 0, 0.1);
$text-shadow: 0 0 14px rgba(0, 0, 0, 0.5);

// transitions
$card-img-transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
$card-img-hover-transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
$card-h3-transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1);
$card-h3-hover-transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1);

//z-indexes

$z-index-my-list: 10;
