// Text selection
// -------------------------------------------------------------------------------
/* stylelint-disable */
@mixin selection {
  //   color: palette(orange, dark);
}

::selection {
  @include selection;
}

::-moz-selection {
  @include selection;
}
/* stylelint-enable */

// Headings
// -------------------------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $weight-bold;
  color: palette(base);
  margin: 0;

  a {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
  }
}
// page titles
h1 {
  font-size: 2.6rem;
  line-height: 3rem;

  @media only screen and (min-width: $screen-large) {
    font-size: 4.8rem;
    line-height: 5.4rem;
  }
}
// secondary headings
h2 {
  font-size: 2.6rem;
  line-height: 3rem;
  @media only screen and (min-width: $screen-large) {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
}
// card titles
h3 {
  font-size: 1.8rem;
  line-height: 2.3rem;
}

/// category titles on document view
h4 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
// TODO: determine styles for h5 to h6
// h5 {
//   font-size: 2.1rem;
//   line-height: 3.1rem;
// }

// h6 {
//   font-size: 1.9rem;
//   line-height: 2.9rem;
// }

// General
// -------------------------------------------------------------------------------
p {
  margin: 0;
  color: palette(base);
  font-family: $base-font-family;
  line-height: 2.4rem;
  font-weight: $weight-regular;
  @media only screen and (min-width: $screen-large) {
    line-height: 3rem;
  }
}

small,
caption,
.small-text,
.caption {
  font-size: 1.4rem;
  line-height: 2.4rem;

  @media only screen and (min-width: $screen-large) {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.subtitle {
  font-size: 2.1rem;
  line-height: 3.1rem;

  @media only screen and (min-width: $screen-large) {
    font-size: 2.8rem;
    line-height: 4.2rem;
  }
}

.blockquote {
  /* stylelint-disable */
  @extend .subtitle;
  /* stylelint-enable */

  font-weight: $weight-medium;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: $weight-black;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted palette(base, x-light);
  cursor: help;
}

address {
  font-style: normal;
}

.weight-regular {
  font-weight: $weight-regular;
}

.weight-medium {
  font-weight: $weight-medium;
}

.weight-bold {
  font-weight: $weight-bold;
}

.weight-black {
  font-weight: $weight-black;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.titlecase {
  text-transform: capitalize;
}

.date,
.location {
  font-size: 1.4rem;
  line-height: 3.2rem;

  @media only screen and (min-width: $screen-large) {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
}

// Links
// -------------------------------------------------------------------------------
a,
.link {
  transition: $default-transition-function $transition-active;
  font-weight: inherit;
  color: palette(red);
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:focus {
    color: palette(red, dark);
  }

  &:link {
    color: palette(red);
  }

  &.link-small {
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }
}

.underline {
  text-decoration: underline;
}

// Style within the_content
// -------------------------------------------------------------------------------

.content-body,
.content-formatting {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $base-font-family;
    margin-bottom: 2rem;
  }

  p {
    font-family: $base-font-family;
    margin-bottom: 2rem;
  }
}

// Code
// -------------------------------------------------------------------------------
pre,
code,
kbd,
samp {
  background-color: palette(base, x-light);
  font-family: monaco, courier, monospace;
}

code {
  font-size: 1.4rem;
  padding: 0 0.25em;
}

pre {
  padding: 2em 2em;
}

// Quotes
q {
  font-style: italic;
}

// larger text button
// ________________________________________________

body.font--large {
  p,
  .menu-item,
  label,
  a,
  input,
  select {
    font-size: 1.8rem;
    line-height: 2.6rem;

    @media only screen and (min-width: $screen-large) {
      font-size: 2.2rem;
      line-height: 3.2rem;
    }
  }

  h1 {
    font-size: 3rem;
    line-height: 3.4rem;

    @media only screen and (min-width: $screen-large) {
      font-size: 5.2rem;
      line-height: 5.8rem;
    }
  }

  h2 {
    font-size: 3rem;
    line-height: 3.4rem;

    @media only screen and (min-width: $screen-large) {
      font-size: 4rem;
      line-height: 4.8rem;
    }
  }

  // card titles
  h3 {
    font-size: 2.2rem;
    line-height: 2.7rem;
  }

  /// category titles on document view
  h4 {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .cta-link {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  button:not(.text-size--small),
  .button {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .filter__list__item {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .pagination__arrow,
  .pagination__page {
    width: 5rem;
    height: 5rem;
  }

  .search-results__wrap .filter__title > span {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
}
