// Layout
// -------------------------------------------------------------
.desktop-only {
  display: none;

  @media only screen and (min-width: $screen-large) {
    display: block;
  }
}

.menu-item,
.menu-item a {
  color: palette(base) !important;
  text-decoration: none;
  line-height: 2.2rem;
  margin-right: 4rem;
  font-weight: $weight-regular;

  &:hover {
    color: palette(red) !important;
  }
}

.invalid-feedback {
  margin: 0;
  color: palette(red);
  font-family: $base-font-family;
  line-height: 2.4rem;
  font-weight: $weight-regular;
  @media only screen and (min-width: $screen-large) {
    line-height: 3rem;
  }
}

.mobile-only {
  display: block;

  @media only screen and (min-width: $screen-medium) {
    display: none;
  }
}

.hide-mobile {

  @media only screen and (max-width: $screen-medium - 1px) {
    display: none !important;
  }
}

.hide-desktop {
  display: block;

  @media only screen and (min-width: $screen-large) {
    display: none;
  }
}

.align {
  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }
}

// Major Margins
.margin {
  &__top {
    &--xsmall {
      margin-top: 1rem;
    }

    &--small {
      margin-top: 2rem;
    }

    &--small-med {
      margin-top: 3rem;
    }

    &--med {
      margin-top: 3rem;

      @media only screen and (min-width: $screen-large) {
        margin-top: 5rem;
      }
    }

    &--med-large {
      margin-top: 4rem;

      @media only screen and (min-width: $screen-large) {
        margin-top: 8rem;
      }
    }

    &--large {
      margin-top: 6rem;

      @media only screen and (min-width: $screen-large) {
        margin-top: 10rem;
      }
    }

    &--xlarge {
      margin-top: 12rem;

      @media only screen and (min-width: $screen-large) {
        margin-top: 16rem;
      }
    }
  }

  &__bottom {
    &--xsmall {
      margin-bottom: 1rem;
    }

    &--small {
      margin-bottom: 2rem;
    }

    &--small-med {
      margin-bottom: 3rem;
    }

    &--med {
      margin-bottom: 3rem;

      @media only screen and (min-width: $screen-large) {
        margin-bottom: 5rem;
      }
    }

    &--med-large {
      margin-bottom: 4rem;

      @media only screen and (min-width: $screen-large) {
        margin-bottom: 8rem;
      }
    }

    &--large {
      margin-bottom: 6rem;

      @media only screen and (min-width: $screen-large) {
        margin-bottom: 10rem;
      }
    }

    &--xlarge {
      margin-bottom: 12rem;

      @media only screen and (min-width: $screen-large) {
        margin-bottom: 16rem;
      }
    }
  }
}

// Major Paddings
.padding {
  &__top {
    &--xsmall {
      padding-top: 1rem;
    }

    &--small {
      padding-top: 2rem;
    }

    &--small-med {
      padding-top: 3rem;
    }

    &--med {
      padding-top: 3rem;

      @media only screen and (min-width: $screen-large) {
        padding-top: 5rem;
      }
    }

    &--med-large {
      padding-top: 4rem;

      @media only screen and (min-width: $screen-large) {
        padding-top: 8rem;
      }
    }

    &--large {
      padding-top: 6rem;

      @media only screen and (min-width: $screen-large) {
        padding-top: 10rem;
      }
    }

    &--xlarge {
      padding-top: 12rem;

      @media only screen and (min-width: $screen-large) {
        padding-top: 16rem;
      }
    }
  }

  &__bottom {
    &--xsmall {
      padding-bottom: 1rem;
    }

    &--small {
      padding-bottom: 2rem;
    }

    &--small-med {
      padding-bottom: 3rem;
    }

    &--med {
      padding-bottom: 3rem;

      @media only screen and (min-width: $screen-large) {
        padding-bottom: 5rem;
      }
    }

    &--med-large {
      padding-bottom: 4rem;

      @media only screen and (min-width: $screen-large) {
        padding-bottom: 8rem;
      }
    }

    &--large {
      padding-bottom: 6rem;

      @media only screen and (min-width: $screen-large) {
        padding-bottom: 10rem;
      }
    }

    &--xlarge {
      padding-bottom: 12rem;

      @media only screen and (min-width: $screen-large) {
        padding-bottom: 16rem;
      }
    }
  }
}

.white {
  color: palette(white);
}

.subtitle {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: $weight-regular;
}

.button-wrap {
  &--center {
    display: flex;
    justify-content: center;
  }
}
