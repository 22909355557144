/**
 * Colour Variables and Palettes => Sass Map
 *
 */

// Base colours
$color-base: #1e1e1e;
$color-white: #fff;

$color-black: #1e1e1e;
$color-black-light: #222021;

$color-off-white: #f5f5f5;
$color-grey: #f4f4f4;
$color-grey-medium: #d4d4d4;
$color-grey-dark: #d8d8d8;
$color-grey-light: #e3e3e3;

$color-red: #9e0918;
$color-red-dark: #7d0713;
$color-red-mcgill: #ed1b2f;
$color-red-lldrd: #f4363d;

// Message colours
$color-error: #cc2841;
$color-valid: #73c4b8;
$color-valid-dark: #8aa37b;
$color-warning: #fccf1e;

$palettes: (
  base: (
    base: $color-base,
    x-light: $color-grey-medium,
    xx-light: $color-grey,
    off-white: $color-off-white
  ),
  black: (
    base: $color-black,
    light: $color-black-light,
  ),
  white: (
    base: $color-white,
  ),
  grey: (
    base: $color-grey,
    medium: $color-grey-medium,
    light: $color-grey-light,
  ),
  red: (
    base: $color-red,
    dark: $color-red-dark,
    error: $color-error,
    mcgill: $color-red-mcgill,
    lldrd: $color-red-lldrd,
  ),
  green: (
    base: $color-valid,
    dark: $color-valid-dark
  ),
);

/* stylelint-disable */
// Generate Palettes
@function palette($palette, $tone: "base") {

  @return map-get(map-get($palettes, $palette), $tone);
}
