.focal-point {
  &__center {
    &--center {
      img {
        object-position: center center;
      }
    }

    &--left {
      img {
        object-position: center left;
      }
    }

    &--right {
      img {
        object-position: center right;
      }
    }
  }

  &__top {
    &--center {
      img {
        object-position: top center;
      }
    }

    &--left {
      img {
        object-position: top left;
      }
    }

    &--right {
      img {
        object-position: top right;
      }
    }
  }

  &__bottom {
    &--center {
      img {
        object-position: bottom center;
      }
    }

    &--left {
      img {
        object-position: bottom left;
      }
    }

    &--right {
      img {
        object-position: bottom right;
      }
    }
  }
}
