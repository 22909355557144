// Lists
// -------------------------------------------------------------------------------
ul {
  list-style: disc outside;
  margin-left: 0;
  margin-bottom: $base-line-height * 1em;

  @media only screen and (min-width: $screen-medium) {
    margin-left: 1em;
  }
}

ol {
  list-style: none;
  counter-reset: cpa-counter;

  li {
    counter-increment: cpa-counter;
    text-indent: -1em;

    &::before {
      content: counter(cpa-counter) ".";
      font-weight: $weight-black;
      margin-right: 16px;
      display: inline-block;
    }
  }
}

li > ol,
li > ul {
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-top: 1.2rem;
}

li {
  margin-bottom: 0.875em;

  &:last-child {
    margin-bottom: 0;
  }
}

// Styles
// -------------------------------------------------------------------------------
ul,
ol {
  &.circle {
    list-style: circle outside;
  }

  &.square {
    list-style: square outside;
  }

  &.none {
    list-style: none;
    margin-left: 0;
  }

  &.leading-zero {
    list-style: decimal-leading-zero;
  }

  &.lower-roman {
    list-style: lower-roman;
  }

  &.upper-roman {
    list-style: upper-roman;
  }

  &.lower-alpha {
    list-style: lower-alpha;
  }

  &.upper-alpha {
    list-style: upper-alpha;
  }
}

// Description Lists
// -------------------------------------------------------------------------------
dl {
  margin-bottom: $base-line-height * 1em;
}

dt {
  font-weight: bold;
  margin-top: $base-line-height * 1em;
}

dd {
  padding-left: $base-line-height * 1em;
}

dl dt:first-child {
  margin-top: 0;
}
