@use "sass:math";
// Fixes & Reset
// -------------------------------------------------------------
input,
select {
  vertical-align: middle;
}

// Webkit adds 2px margin
button,
input,
select,
textarea {
  margin: 0;
}
// Make buttons play nice in IE
button {
  width: auto;
  overflow: visible;
}

// General
// -------------------------------------------------------------
fieldset {
  border: none;
  padding: 0;
}

legend {
  font-weight: $weight-black;
  padding: 0 1em;
  margin-left: -1em;
  display: block;
}

label,
.label {
  display: block;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: $weight-medium;
  opacity: 0.6;
}

// Fields
// -------------------------------------------------------------

select {
  background-color: transparent;
  border: 1px solid rgba(palette(black), 0.3);
  color: rgba(palette(black), 0.6);
  display: block;
  font-size: 1.6rem;
  transition: all 0.2s linear 0s;
  border-radius: 0;
  width: 100%;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;

  &:not([type="range"]):not([type="submit"]):focus {
    border-color: palette(black);
  }
}

select[multiple] {
  height: 6.5em;
  padding: 1em;
}

.select-wrapper-arrow {
  position: relative;

  &::after {
    content: url("../../images/icons/icon-arrow-down.svg");
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

textarea,
input,
.text-input {
  appearance: none !important;
  padding: 1.5rem 12rem 1.5rem 1.5rem;
  font-size: 1.6rem;
  line-height: 1.7rem;
  width: 100%;
  border: 1px solid rgba(palette(black), 0.3);
  font-family: $base-font-family;
  -webkit-appearance: none;
  border-radius: 0;
  margin-bottom: 2rem;
}

textarea {
  height: 150px;
  resize: vertical;
  font-family: $base-font-family;
  -webkit-appearance: none;
}

/* stylelint-disable */
// Placeholder - Stacking them doesn't work
@mixin placeholder {
  color: palette(base);
  font-size: 1.6rem;
  border-radius: 0;
  font-weight: $weight-black;
}

::-webkit-input-placeholder {
  @include placeholder;
}

::-moz-placeholder {
  @include placeholder;
}

// Firefox extra spacing
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input:-ms-input-placeholder {
  @include placeholder;
}
/* stylelint-enable */

// Radio & Checkbox
// -------------------------------------------------------------
$radiocheckbox-size: 1.6em;

.checkbox,
.radio {
  cursor: pointer;
  display: block;
  padding-left: $radiocheckbox-size * 1.5;
  margin-bottom: 1em;
  position: relative;

  &.inline {
    display: inline-block;
    margin-right: 1em;
  }

  input {
    background-color: palette(base, x-light);
    border: 0;
    border-radius: $base-border-radius;
    cursor: pointer;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  padding: 0;
  margin: 0;
  position: relative;

  &::before {
    content: "";
  }
}

input[type="checkbox"] {
  border-radius: 0;
  text-align: center;

  &::before {
    content: "✕";
    display: block;
    font-weight: $weight-bold;
    line-height: $radiocheckbox_size;
    transform: translate(-1000%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &:checked::before {
    transform: translate(-50%, -50%);
  }
}

input[type="radio"] {
  border-radius: 100%;

  &::before {
    border-radius: 100%;
    content: "";
    margin: math.div(-$radiocheckbox-size, 2);
    width: math.div($radiocheckbox-size, 2);
    height: math.div($radiocheckbox-size, 2);
    position: relative;
    top: 50%;
    left: -100%;
  }

  &:checked::before {
    display: block;
    left: 50%;
  }
}

// Buttons
// -------------------------------------------------------------------------------
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

// File Uploads
// -------------------------------------------------------------------------------
input[type="file"] {
  padding: 1em;
  border: 1px solid palette(base, x-light);
  border-radius: $base-border-radius;
}

// Field sizes
// -------------------------------------------------------------------------------
.field-xsmall {
  width: 2em;
}

.field-small {
  width: 3em;
}

.field-medium {
  width: 4em;
}

.field-large {
  width: 6em;
}

// Field Helpers
// -------------------------------------------------------------------------------
.field-error,
.field-help {
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-style: italic;
  padding-top: 0.5em;
  font-family: $base-font-family;
  margin: 0;
  width: 49%;
  text-transform: inherit !important;
  letter-spacing: inherit !important;
}

.field-error {
  color: $color-error;
  float: right;
  text-align: right;
}

.field-help {
  float: left;
}

.field-success{
  font-size: 2rem;
  line-height: 2.8rem;
  font-style: italic;
  padding-top: 2rem;
  font-family: $base-font-family;
  margin: 0;
  color: $color-valid-dark;

}

// Form Layout
// -------------------------------------------------------------------------------
form {
  ol,
  ol li {
    > li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  ol {
    li {
      padding-bottom: 1.8em;

      &::after {
        content: "";
        display: table;
        clear: both;
      }

      input,
      select,
      textarea {
        width: 100%;
      }

      li:last-child {
        padding-bottom: 0;
      }

      select {
        width: 100%;
      }
    }
  }
}
