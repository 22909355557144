// Buttons
// --------------------------------------------------
button,
.button {
  border-radius: $base-border-radius;
  border: 1px solid palette(red);
  color: palette(white);
  display: inline-block;
  font-weight: $weight-bold;
  text-decoration: none;
  text-align: center;
  font-family: $base-font-family;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.3rem 2.5rem 0.9rem;
  transition: all $transition-passive;
  cursor: pointer;
  background-color: palette(red);
  text-transform: uppercase;

  &:hover,
  &:focus {
    transition: all $transition-active;
    background-color: palette(red, dark);
    color: palette(white);
  }

  &:active {
    background-color: palette(red, dark);
  }
}

a {
  &:link {
    &.button {
      color: palette(white);
    }
  }
}

// CTA Link
// ---------------------------------------------

.cta-link {
  color: palette(red);
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: $weight-bold;
  transition: all $transition-passive;
  cursor: pointer;
  text-transform: uppercase;

  &::after {
    content: url("../../images/icons/arrow-next.svg");
    margin-left: 1rem;
    transition: all $transition-passive;
  }

  &:hover::after {
    transition: all $transition-active;
    margin-left: 1.5rem;
  }

  &:active {
    transform: translateX(3px);
  }

  &:hover,
  &:focus {
    color: palette(red, dark);
    transition: all $transition-active;
  }
}

// Menu Items
// ---------------------------------------------

.menu-item {
  color: palette(base);
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: $weight-black;
  cursor: pointer;
  position: relative;
  transition: $default-transition-function $transition-passive;

  &:hover,
  &:focus {
    color: palette(base);

    &::after {
      content: "";
      margin-top: 2px;
      height: 4px;
      width: 100%;
      z-index: -1;
      position: absolute;
      left: 0;
      bottom: -2px;
      //   background-color: palette(orange);
      transition: $default-transition-function $transition-active;
    }
  }
}

/// view buttons

.button--view {
  @extend .button;

  padding: 0.9rem 1.4rem 0.9rem 3.7rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  position: relative;
  transition: all $transition-passive;

  &::before {
    // to do: get icons that are the same size as each other from
    content: "";
    background: url("../../images/icons/view-white.svg") no-repeat;
    transition: all $transition-passive;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 13px;
  }

  &:hover,
  &:focus {
    color: palette(red) !important;
    background-color: palette(white);
    transition: all $transition-active;
    border: 1px solid rgba(palette(red), 0.35);

    &::before {
      content: "";
      background: url("../../images/icons/view-red.svg") no-repeat;
      transition: all $transition-active;
    }
  }
}

.button--download {
  @extend .button;

  font-size: 1.4rem;
  line-height: 1.6rem;
  position: relative;

  &::before {
    content: "";
    background: url("../../images/icons/download.svg") no-repeat;
    width: 18px;
    height: 18px;
    transition: all $transition-passive;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &--large {
    @extend .button--download;

    padding: 1.5rem 6.5rem 1.5rem 7.5rem;

    &::before {
      left: 4.5rem;
    }
  }
}

// ghost button

.button--list {
  @extend .button;

  padding: 0.9rem 1.4rem 0.9rem 3.7rem;
  color: palette(red);
  background-color: palette(white);
  border: 1px solid rgba(palette(red), 0.35);
  font-size: 1.4rem;
  line-height: 1.6rem;
  position: relative;

  &::before {
    content: "";
    background: url("../../images/icons/list-red.svg") no-repeat;
    transition: all $transition-passive;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 15px;
  }

  &:hover,
  &:focus {
    color: palette(white);
    background-color: palette(red);
    transition: all $transition-active;

    &::before {
      background: url("../../images/icons/list-white.svg") no-repeat;
      transition: all $transition-active;
    }
  }

  &--large {
    @extend .button--list;

    padding: 1.5rem 6.5rem 1.5rem 7.5rem;

    &::before {
      left: 4.5rem;
    }
  }
}

.button--added {
  @extend .button;

  position: relative;
  padding-left: 4rem;

  &::before {
    content: "";
    background: url("../../images/icons/added-check.svg") no-repeat;
    width: 16px;
    height: 13px;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.button--load-more {
  @extend .button--list;

  padding: 1.3rem 1.4rem;
  width: 100%;
  margin-top: 3.9rem;

  &::before {
    display: none;
  }
  @media only screen and (min-width: $screen-small) {
    width: unset;
    margin: 3.6rem auto 0;
    display: block;
  }
  @media only screen and (min-width: $screen-medium) {
    margin: 8rem auto 0;
  }
}

.button--underline {
  color: palette(red);
  background-color: transparent;
  border: none;
  text-transform: capitalize;
  text-decoration: underline;
  letter-spacing: normal;
  font-weight: $weight-regular;

  &:hover,
  &:focus {
    background-color: transparent;
    color: palette(red, dark);
  }
}
